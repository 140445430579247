import {FedopsInteractions, FieldMasks} from '../../../components/Checkout/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {subscribe} from '@wix/ambassador-ecom-v1-subscribe-request/http';
import {HttpError} from '@wix/http-client';
import {CheckoutModel} from '../../models/checkout/Checkout.model';
import {CheckoutErrorCode} from '../../utils/errors';
import {CheckoutErrorModel} from '../../models/checkout/CheckoutError.model';
import {BIService} from './BIService';
import {NavigationService} from './NavigationService';
import {ApiAddress, Checkout, PlaceOrderUrlParams, StepId} from '../../../types/checkoutApp.types';
import {ambassadorWithHeaders} from '../../utils/ambassador.utils';
import {
  ApiAddressFragment,
  CheckoutFragment,
  CustomFieldFragment,
  FullAddressContactDetailsFragment,
  MultiCurrencyPriceFragment,
} from '../../../gql/graphql';
import {AutoGraphqlApi} from '../../apis/checkout/AutoGraphqlApi';
import {PaymentError} from '../../../types/payment.types';
import {UserFieldsNamespaces} from '../../utils/types.util';
import {toMonitorError} from '../../utils/errorMonitor.utils';
import {isSubdivisionsEqual} from '../../../components/Checkout/Form/form.utils';
import {CheckoutSettingsService} from './CheckoutSettingsService';
import {SPECS} from '../../../common/constants';

export interface MinimumOrderErrorData {
  minimumOrderAmount: MultiCurrencyPriceFragment;
  remaining: MultiCurrencyPriceFragment;
}

export class CheckoutService {
  private readonly checkoutId?: string;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly navigationService: NavigationService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly biService: BIService;
  public readonly currency?: string;
  private originalShippingOptionTitle: string = '';
  public isPickupButShippingFlow: boolean = false;
  private readonly autoGraphqlApi: AutoGraphqlApi;

  public checkout!: CheckoutModel;
  public placeOrderError?: CheckoutErrorModel;
  public updateCheckoutError?: CheckoutErrorModel;
  public applyCouponError?: CheckoutErrorModel;
  public applyGiftCardError?: CheckoutErrorModel;
  public hasPartialOutOfStockError?: boolean;

  constructor({
    flowAPI,
    siteStore,
    biService,
    navigationService,
    checkoutSettingsService,
    currency,
  }: {
    flowAPI: ControllerFlowAPI;
    siteStore: SiteStore;
    biService: BIService;
    navigationService: NavigationService;
    checkoutSettingsService: CheckoutSettingsService;
    currency?: string;
  }) {
    this.flowAPI = flowAPI;
    this.biService = biService;
    this.siteStore = siteStore;
    this.currency = currency;
    this.navigationService = navigationService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.checkoutId = this.navigationService.checkoutId;
    this.autoGraphqlApi = new AutoGraphqlApi({flowAPI, siteStore, currency});
  }

  public async init(): Promise<void> {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.FetchCheckout);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.FetchCheckout).start();
    await this.fetchCheckout();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.FetchCheckout);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.FetchCheckout).finish();
    this.originalShippingOptionTitle = this.checkout.selectedShippingOption?.title ?? '';
  }

  public setIsPickupButShippingFlow(isPickupButShippingFlow: boolean) {
    this.isPickupButShippingFlow = isPickupButShippingFlow;
  }

  public setUpdateCheckoutError({code, data, type}: {code: string; data?: Record<string, any> | null; type: string}) {
    this.updateCheckoutError = new CheckoutErrorModel({code, data, type});
  }

  public async fetchCheckout({update}: {update?: boolean} = {}): Promise<void> {
    if (!this.checkoutId) {
      console.error('No checkoutId in appSectionParams');
      throw new Error('no checkout id');
    }

    let data;
    /* istanbul ignore else: test with slots */

    if (!update) {
      data = (await this.autoGraphqlApi.getCheckout(this.checkoutId)).data;
    } else {
      data = (await this.autoGraphqlApi.updateCheckout({checkout: {id: this.checkoutId}})).data;
    }

    this.hasPartialOutOfStockError = data.checkout?.lineItems?.some((lineItem) => lineItem?.quantity === 0);

    /* istanbul ignore next */
    if (data.checkout) {
      this.setCheckout(data.checkout);
    } else {
      /* istanbul ignore next */
      console.error('No checkout data from the API');
      /* istanbul ignore next */
      throw new Error('No checkout data');
    }
  }

  public async createOrderAndCharge(
    paymentDetailsId: string | undefined,
    urlParams: PlaceOrderUrlParams
  ): Promise<{orderId?: string | null; paymentResponseToken?: string | null; paymentError?: PaymentError} | undefined> {
    try {
      const variables = {
        id: this.checkout.id,
        paymentToken: paymentDetailsId ?? this.navigationService.cashierPaymentId,
        savePaymentMethod: this.checkout.isCardTokenizationCheckout,
        delayCapture: this.checkoutSettingsService.checkoutSettings.delayCaptureEnabled,
        urlParams,
      };
      const {data} = await this.autoGraphqlApi.createOrderAndCharge(variables);
      return data;
    } catch (error) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(error, 'createOrderAndCharge'));
      return this.handlePlaceOrderError(error as HttpError);
    }
  }

  private handlePlaceOrderError(error: HttpError): {orderId?: string; paymentError?: PaymentError} | undefined {
    const errorModel = CheckoutErrorModel.fromHttpError(error);
    if (errorModel.code === CheckoutErrorCode.CHECKOUT_ALREADY_PAID) {
      return {orderId: errorModel.data?.orderId ?? errorModel.data?.subscriptionId};
    }
    if (errorModel.code === CheckoutErrorCode.PAYMENT_ERROR) {
      return {
        paymentError: {
          status: errorModel.data?.transactionStatus,
          failureDetails: errorModel.data?.failureDetails,
        } as PaymentError,
      };
    }
    this.placeOrderError = errorModel;

    if (this.placeOrderError.code === CheckoutErrorCode.GENERAL_ERROR) {
      this.biService.checkoutErrorTrackingForDevelopers(
        JSON.stringify(error?.response?.data),
        JSON.stringify(this.placeOrderError.data)
      );
    }
  }

  public async applyCoupon(couponCode: string, mobilePosition?: string): Promise<void> {
    try {
      const updatePayload = {
        checkout: {
          id: this.checkoutId,
        },
        couponCode,
      };
      const {data} = await this.autoGraphqlApi.updateCheckout(updatePayload);
      this.setCheckout(data.checkout!);
      this.biService.couponApplied({checkout: this.checkout, mobilePosition});
    } catch (error) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(error));
      this.applyCouponError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.errorWhenApplyingACoupon({
        couponCode,
        applyCouponError: this.applyCouponError,
        checkout: this.checkout,
        mobilePosition,
      });
    }
  }

  public async removeCoupon(mobilePosition?: string): Promise<void> {
    if (this.checkout.appliedCoupon?.code) {
      this.biService.removeACoupon({checkout: this.checkout, mobilePosition});
      const removeCouponPayload = {
        id: this.checkout.id,
      };
      const {data} = await this.autoGraphqlApi.removeCoupon(removeCouponPayload);
      this.setCheckout(data.checkout!);
    }
    this.applyCouponError = undefined;
  }

  public async applyGiftCard(giftCardCode: string, mobilePosition?: string): Promise<void> {
    try {
      const updatePayload = {
        checkout: {
          id: this.checkout.id,
        },
        giftCardCode,
      };
      const {data} = await this.autoGraphqlApi.updateCheckout(updatePayload);
      this.setCheckout(data.checkout!);
      this.biService.giftCardCheckoutCodeApplied(this.checkout, mobilePosition);
    } catch (error) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(error));
      this.applyGiftCardError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.checkoutErrorWhenApplyingAGiftCard(this.applyGiftCardError, this.checkout, mobilePosition);
    }
  }

  public async removeGiftCard(mobilePosition?: string): Promise<void> {
    if (this.checkout.giftCard?.obfuscatedCode) {
      this.biService.giftCardCheckoutRemoveCode(this.checkout, mobilePosition);
      const removePayload = {
        id: this.checkout.id,
      };
      const {data} = await this.autoGraphqlApi.removeGiftCard(removePayload);
      this.setCheckout(data.checkout!);
    }
    this.applyGiftCardError = undefined;
  }

  public async subscribe(): Promise<void> {
    await ambassadorWithHeaders(
      subscribe({
        email: this.checkout.buyerInfo.email,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
  }

  public async setBillingDetails({
    contactDetails,
    address,
    addressesServiceId,
  }: {
    contactDetails: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
  }): Promise<void> {
    return this.updateCheckout(
      {
        billingInfo: {
          contactDetails,
          ...(address ? {address} : /* istanbul ignore next */ {}),
          ...(addressesServiceId ? {addressesServiceId} : {}),
        },
      },
      [
        FieldMasks.billingContact,
        ...(address ? [FieldMasks.billingAddress] : /* istanbul ignore next */ []),
        ...(addressesServiceId ? [FieldMasks.billingAddressesServiceId] : []),
      ]
    );
  }

  public async setShippingInfo({
    contactDetails,
    email,
    customField,
    extendedFields,
    shippingAddress,
    addressesServiceId,
  }: {
    contactDetails: FullAddressContactDetailsFragment;
    email?: string;
    customField?: CustomFieldFragment;
    extendedFields?: any;
    shippingAddress?: ApiAddress;
    addressesServiceId?: string;
  }): Promise<void> {
    const extendedFieldsNamespaces: UserFieldsNamespaces = {
      _user_fields: extendedFields,
    };
    return this.updateCheckout(
      {
        buyerInfo: {email},
        shippingInfo: {
          shippingDestination: shippingAddress
            ? {contactDetails, address: shippingAddress, addressesServiceId}
            : {contactDetails, addressesServiceId},
        },
        ...(extendedFields
          ? {
              extendedFields: {
                namespaces: extendedFieldsNamespaces,
              },
            }
          : {}),
        ...(customField ? {customFields: [customField]} : {}),
      },
      [
        FieldMasks.shippingContact,
        ...(extendedFields ? [FieldMasks.extendedFields] : []),
        ...(email ? [FieldMasks.buyerInfoEmail] : []),
        FieldMasks.shippingAddressesServiceId,
        ...(shippingAddress ? [FieldMasks.shippingAddress] : []),
        ...(customField ? [FieldMasks.customField] : []),
      ]
    );
  }

  public async setFastFlowFormFields({
    extendedFields,
    customField,
  }: {
    extendedFields: any;
    customField?: CustomFieldFragment;
  }): Promise<void> {
    const extendedFieldsNamespaces: UserFieldsNamespaces = {
      _user_fields: extendedFields,
    };
    return this.updateCheckout(
      {
        extendedFields: {
          namespaces: extendedFieldsNamespaces,
        },
        ...(customField ? {customFields: [customField]} : {}),
      },
      [FieldMasks.extendedFields, ...(customField ? [FieldMasks.customField] : [])]
    );
  }

  public async setBillingAddress(billingAddress: ApiAddressFragment): Promise<void> {
    return this.updateCheckout({billingInfo: {address: billingAddress}}, [FieldMasks.billingAddress]);
  }

  public async setSingleAddress(address: ApiAddressFragment): Promise<void> {
    const {geocode: _, ...addressNoGeo} = address;
    return this.updateCheckout({billingInfo: {address: addressNoGeo}, shippingInfo: {shippingDestination: {address}}}, [
      FieldMasks.billingAddress,
      FieldMasks.shippingAddress,
    ]);
  }

  public async setShippingOption(shippingOptionId: string): Promise<void> {
    return this.updateCheckout({shippingInfo: {selectedCarrierServiceOption: {code: shippingOptionId}}}, [
      FieldMasks.selectedCarrierServiceOption,
    ]);
  }

  private isUserChangedDeliveryAddress(checkout: CheckoutFragment): boolean {
    const currentAddress = this.checkout.shippingDestination?.address;
    const incomingAddress = checkout.shippingInfo?.shippingDestination?.address;
    return (
      currentAddress?.addressLine !== incomingAddress?.addressLine ||
      currentAddress?.country !== incomingAddress?.country ||
      currentAddress?.postalCode !== incomingAddress?.postalCode ||
      currentAddress?.streetAddress !== incomingAddress?.streetAddress ||
      !isSubdivisionsEqual(currentAddress?.subdivision, incomingAddress?.subdivision) ||
      currentAddress?.city !== incomingAddress?.city
    );
  }

  private getGeoCodeShippingAddressOverride(checkout: CheckoutFragment): Partial<CheckoutFragment> {
    /* istanbul ignore next */
    if (!checkout.shippingInfo?.shippingDestination) {
      return {};
    }

    return {
      shippingInfo: {
        ...checkout.shippingInfo,
        shippingDestination: {
          ...checkout.shippingInfo.shippingDestination,
          address: {
            ...checkout.shippingInfo.shippingDestination.address,
            geocode: this.isUserChangedDeliveryAddress(checkout)
              ? undefined
              : this.checkout.shippingDestination?.address.geocode,
          },
        },
      },
    };
  }

  private async updateCheckout(checkout: Partial<Omit<Checkout, 'id'>>, fieldMask: FieldMasks[]): Promise<void> {
    try {
      const updatePayload: {checkout: Checkout; fieldMask: FieldMasks[]} = {
        checkout: {
          id: this.checkout.id,
          ...checkout,
          ...(fieldMask.includes(FieldMasks.shippingAddress) ? this.getGeoCodeShippingAddressOverride(checkout) : {}),
        },
        fieldMask,
      };
      const {data} = await this.autoGraphqlApi.updateCheckout(updatePayload);
      this.setCheckout(data.checkout!);
    } catch (error) {
      this.updateCheckoutError = CheckoutErrorModel.fromHttpError(error as HttpError);
    }
  }

  public async removeLineItem(lineItemId: string): Promise<void> {
    const removePayload = {
      id: this.checkout.id,
      lineItemIds: [lineItemId],
    };
    const {data} = await this.autoGraphqlApi.removeLineItem(removePayload);
    this.setCheckout(data.checkout!);
  }

  public clearPlaceOrderError(): void {
    this.placeOrderError = undefined;
  }

  /* istanbul ignore next */
  public setPlaceOrderPaymentError(paymentError: PaymentError, errorCode: string): void {
    /* istanbul ignore next */
    this.placeOrderError = CheckoutErrorModel.fromPaymentError(paymentError, errorCode);
    this.biService.sendFailedToCompleteOrderBIEvent(this.checkout, {
      stage: StepId.paymentAndPlaceOrder,
      field: this.placeOrderError.code,
      errorMessage: JSON.stringify(this.placeOrderError),
    });
  }
  public clearUpdateCheckoutError(): void {
    this.updateCheckoutError = undefined;
  }

  public get originalShippingTitle(): string {
    return this.originalShippingOptionTitle;
  }

  private setCheckout(checkout: Checkout) {
    const shouldSendTrackEventWhenCheckoutLoad = this.flowAPI.experiments.enabled(SPECS.SendTrackEventWhenCheckoutLoad);
    const supportDeliveryViolationsOnCheckout = this.flowAPI.experiments.enabled(
      SPECS.SupportDeliveryViolationsOnCheckout
    );

    this.checkout = new CheckoutModel(checkout, {
      timezone: this.flowAPI.controllerConfig.wixCodeApi.site.timezone,
      shouldSendTrackEventWhenCheckoutLoad,
      supportDeliveryViolationsOnCheckout,
    });
  }
}
